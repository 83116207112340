
import React, {useState} from 'react'
import { Container, Accordion } from "react-bootstrap";

import NavMonewReport from './NavMonewReport';
import HospitalReport from './HospitalReport'

export default function MoneyIndex() {


  const [loadHospitals, setLoadHospital] = useState(false);

  return (
    
    <div className='moneyIndex smallWrapper' >
      <h1 className="titleStyle">כספים</h1>

      <Container fluid className='navMonewReport'>
        <div className='accordionSec'>

            {/* defaultActiveKey="accordion0" */}
            <Accordion className="accordions acc1"   >

                <Accordion.Item eventKey="accordion0" >
                    
                    <Accordion.Header>
                      <span>ייצוא שכר</span>
                    </Accordion.Header>

                    <Accordion.Body>

                        <div className='accordeonContent'>
                          <NavMonewReport />
                        </div>

                    </Accordion.Body>

                </Accordion.Item>


                <Accordion.Item eventKey="accordion2" >
                    
                    <Accordion.Header onClick={()=>setLoadHospital(true)}>
                      <span>דו"ח גביה בתי חולים</span>
                    </Accordion.Header>

                    <Accordion.Body>

                        <div className='accordeonContent'>
                          {loadHospitals && <HospitalReport /> }
                        </div>

                    </Accordion.Body>

                </Accordion.Item>
                
            </Accordion>

        </div>
      </Container>
      

    </div>
  )
}
