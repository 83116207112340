import React from 'react';
import { NavLink } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { RestUrls } from "../../api/config";
import logoNathan from './../../../img/nathan/logo.png';
import ic1 from '../../../img/nathan/menuIcons/ic1.svg';
import ic2 from '../../../img/nathan/menuIcons/ic2.svg';
import money from '../../../img/nathan/menuIcons/money.svg';
import config from '../../../img/nathan/menuIcons/config.svg';
import logOut from '../../../img/nathan/menuIcons/logOut.svg';

const NavRight = ({ page }) => {
    const user = JSON.parse(localStorage.getItem('userData'));
    const currentPage = page;

    const scrollToTop = () => scroll.scrollToTop({ duration: 0 });

    return (
        <div className='site-menu'>
            <div className="mainMenuTable">
                <div className="fixedPosition">
                    <header>
                        <NavLink className="logOut" to='/logout'>
                            <img src={logOut} alt="Logout" />
                            <span>התנתקות</span>
                        </NavLink>
                        <a href={RestUrls.appUrl} target="_self" rel="noopener noreferrer">
                            <img className="logoClient" src={logoNathan} alt="נתן - רפואה שיקום וסיעוד" />
                        </a>
                    </header>

                    <nav className="mainMenuNav">
                        <NavLink
                            className={({ isActive }) => isActive ? 'is-active' : ''}
                            role="menuitem"
                            onClick={scrollToTop}
                            to='/userConsole'
                        >
                            <img className='bulletMainMenu' src={ic1} alt='בולט' />
                            <span>הזמנוות</span>
                        </NavLink>

                        {user.hospital && user.hospital.length === 0 && (
                            <>
                                <NavLink
                                    className={({ isActive }) => isActive ? 'is-active' : ''}
                                    role="menuitem"
                                    onClick={scrollToTop}
                                    to='/workers'
                                >
                                    <img className='bulletMainMenu' src={ic2} alt='בולט' />
                                    <span>עובדים</span>
                                </NavLink>

                                <NavLink
                                    className={({ isActive }) => isActive ? 'is-active' : ''}
                                    role="menuitem"
                                    onClick={scrollToTop}
                                    to='/config'
                                >
                                    <img className='bulletMainMenu' src={config} alt='בולט' />
                                    <span>הגדרות מערכת</span>
                                </NavLink>

                                <NavLink
                                    className={({ isActive }) => isActive ? 'is-active' : ''}
                                    role="menuitem"
                                    onClick={scrollToTop}
                                    to='/money'
                                >
                                    <img className='bulletMainMenu' src={money} alt='בולט' />
                                    <span>כספים</span>
                                </NavLink>
                            </>
                        )}
                    </nav>

                    <div className="credit">
                        <div className="userData clear">
                            <div className="circleGrey">{user.name.charAt(0)}</div>
                            <span>{user.name}</span>
                        </div>
                        <a href="https://waveproject.co.il/" target="_blank" rel="noopener noreferrer">
                            <img src="https://leads.wdev.co.il/commonAssetsWave/credit/wp-b-hd.png" alt="WaveProject" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavRight;
