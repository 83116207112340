import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll';
import { toast } from 'react-toastify';

import { getAllUrlParams } from '../../models/UrlParameters';
import { orderSatus, showCancelStatus, replaceText } from '../../models/ordersFuncNathan';
import getDataFromApi from '../../api/getDataFromApi';

import SingleOrderAcordOrderDetails from './SingleOrderAcordOrderDetails';
import WorkerLinkMain from './WorkerLinkMain';
import OrderPayIndex from './OrderPayIndex';
import OrderStatusIndex from './OrderStatusIndex';

import loader from '../../../img/preLoader.gif';

const SingleOrder = ({ editOrderId, currentSearchStatus, goBack }) => {
  const [state, setState] = useState({
    currentSearchStatus: currentSearchStatus || false,
    step: '1',
    orderId: editOrderId || getAllUrlParams(window.location.search)?.orderid || false,
    loading: false,
    orderData: false,
    loadPays: false,
    loadStatus: false,
    isNewOrder: false,
    isRetroOrder: false,
    reloadOrders: false,
    loadWorkerLink: false,
    cancelRequest: false
  });

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    if (state.orderId) {
      navigate(`/order?orderId=${state.orderId}`, { replace: true });
      getOrder(state.orderId);
    }
  }, [state.orderId]);

  const getOrder = (orderId) => {
    setState(prev => ({ ...prev, orderData: false }));
    sendtoAdmin('getOrder', 'orders', { orderId }, 'orderData');
  };

  const handleGoBack = () => {
    goBack({ ...state, editOrderId: false });
  };

  const newOrderIdHandle = (id, isRetroOrder = false) => {
    setState(prev => ({
      ...prev,
      step: '2',
      orderId: id,
      isNewOrder: true,
      isRetroOrder
    }));
    getOrder(id);
  };

  const loadAcc = (name) => {
    if (!state[name]) {
      setState(prev => ({ ...prev, [name]: true }));
    }
  };

  const cancelOrderRequest = () => {
    const sendObj = {
      orderId: state.orderId,
      userName: user.name,
      hospitalName: user.hospital.name
    };
    cancelOrderRequestApi('cancelOrderRequestSMS', 'orders', sendObj, 'cancelRequest');
  };

  const sendtoAdmin = (url, controller, objectToSend, setStateName = 'data', auth = 'all') => {
    setState(prev => ({ ...prev, loading: true }));
    getDataFromApi(url, objectToSend, controller, auth)
      .then(data => {
        setState(prev => ({ 
          ...prev, 
          [setStateName]: data, 
          loading: false,
          step: determineStep(data)
        }));
      });
  };

  const determineStep = (data) => {
    if (!data?.order) return '1';
    const { orderStatus, is_retroOrder, nurseData, is_prati } = data.order;
    if (orderStatus === '0' || (is_retroOrder === '1' && !nurseData?.id)) return '2';
    if (['2', '3'].includes(orderStatus)) return is_prati === '1' ? '3' : '4';
    return '1';
  };

  const cancelOrderRequestApi = (url, controller, objectToSend, setStateName = 'data', auth = 'all') => {
    setState(prev => ({ ...prev, loading: true }));
    getDataFromApi(url, objectToSend, controller, auth)
      .then(data => {
        setState(prev => ({ ...prev, [setStateName]: true, loading: false }));
        data?.ok ? toast.success(data.ok) : toast.error('שגיאה');
      });
  };

  const { orderData, loading, step, isNewOrder, loadWorkerLink, loadPays, loadStatus, cancelRequest } = state;
  const order = orderData?.order;
  const btnStatus = order ? orderSatus(order.orderStatus) : '';

  if (orderData && !order?.id) {
    return (
      <div className="smallWrapper">
        <img src={loader} alt="loader" className={loading ? "loader active animate__animated animate__fadeIn" : "loader"} />
        <h1 className="titleStyle">הזמנת שירות</h1>
        <div className="goBackBtnCont">
          <button onClick={() => window.open('/userConsole', '_self')} className="goBackBtn bounce2">« חזור</button>
        </div>
        <p style={{color: 'red'}}>שגיאה בקישור ההזמנה / ההזמנה לא קיימת</p>
      </div>
    );
  }

  return (
    <div className="smallWrapper">
      <img src={loader} alt="loader" className={loading ? "loader active animate__animated animate__fadeIn" : "loader"} />
      {goBack && <div className="goBackBtnCont"><button onClick={handleGoBack} className="goBackBtn bounce2">« חזור</button></div>}
      <h1 className="titleStyle">הזמנת שירות</h1>
      {user.userCredential !== '1' && state.orderId && !order?.cancelRequest && (
        <button 
          disabled={cancelRequest || loading} 
          className={`defaultBtnPic noPic noPadd ${!cancelRequest ? 'cancelOrderBtn' : 'cancelOrderBtn grey'}`} 
          onClick={cancelOrderRequest}
        >
          בקשה לביטול הזמנה
        </button>
      )}
      {order?.cancelRequest && (
        <button disabled className="defaultBtnPic noPic noPadd cancelOrderBtn">
          נשלחה בקשה לביטול הזמנה ב- {order.cancelRequest}
        </button>
      )}
      <div className="accordionSec">
        <Accordion className="accordions acc1" defaultActiveKey='openMe'>
          <Accordion.Item eventKey={step === '1' ? 'openMe' : 'no1'}>
            <Accordion.Header>
              <span>פרטי הזמנה</span>
              {order && (
                <header className='editHazmanaHeader'>
                  <ul>
                    <li><span>סטטוס:</span><div className={`showStatus ${btnStatus.nameClass}`}>{replaceText(btnStatus.text)} {showCancelStatus(order)}</div></li>
                    <li><span>תאריך פתיחה: </span><strong>{order.created_at}</strong></li>
                    <li><span>נפתח על ידי: </span><strong>{order.openUserName}</strong></li>
                  </ul>
                </header>
              )}
            </Accordion.Header>
            <Accordion.Body>
              {(state.orderId && order?.id) || !state.orderId ? (
                <SingleOrderAcordOrderDetails 
                  goBack={handleGoBack} 
                  orderData={orderData} 
                  reloadOrders={() => setState(prev => ({ ...prev, reloadOrders: true }))} 
                  newOrderId={newOrderIdHandle} 
                />
              ) : false}
            </Accordion.Body>
          </Accordion.Item>

          {state.orderId && (!order || order.orderStatus !== '4') && (
            <Accordion.Item onClick={() => loadAcc('loadWorkerLink')} eventKey={step === '2' ? 'openMe' : 'no2'}>
              <Accordion.Header>
                <span>שיוך עובד</span>
              </Accordion.Header>
              <Accordion.Body>
                <div className='acordCont colorDown noPaddLR'>
                  {isNewOrder && user.userCredential === '1' && (
                    <header className='isNewOrder addPadd'>
                      <h2 className='okH2 animate__animated animate__bounceIn'>בחרו עובד לשייך להזמנה: </h2>
                    </header>
                  )}
                  {(loadWorkerLink || step === '2') && order && (
                    <WorkerLinkMain 
                      orderData={order} 
                      is_4selectWorker={true} 
                      reloadOrders={() => setState(prev => ({ ...prev, reloadOrders: true }))} 
                    />
                  )}
                  {isNewOrder && (
                    <div className='goBackPageCont addPadd'>
                      <NavLink
                        className='defaultBtnPic noPic greyBack'
                        role="menuitem" 
                        onClick={() => scroll.scrollToTop({duration: 0})}
                        to='/userConsole'
                      >
                        <span>&laquo; חזרה לכל ההזמנות</span>
                      </NavLink>
                    </div>
                  )}
                  {goBack && (
                    <div className='addPadd'>
                      <button className='defaultBtnPic noPic noPadd' onClick={handleGoBack}>
                        <span>&laquo; חזרה לכל ההזמנות</span>
                      </button>
                    </div>
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )}

          {order && order.is_prati === '1' && user.userCredential === '1' && (
            <Accordion.Item onClick={() => loadAcc('loadPays')} eventKey={step === '3' ? 'openMe' : 'no3'}>
              <Accordion.Header>
                <span>גביית תשלום</span> <small>(#{order.id})</small>
              </Accordion.Header>
              <Accordion.Body>
                {(loadPays || step === '3') && (
                  <div className='acordCont colorDown noPaddLR' style={{paddingTop: '0'}}>
                    <OrderPayIndex 
                      orderData={order} 
                      reloadOrders={() => setState(prev => ({ ...prev, reloadOrders: true }))} 
                      reloadOrder={() => getOrder(state.orderId)} 
                    />
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          )}

          {order && order.is_prati !== '1' && user.userCredential === '1' && (
            <Accordion.Item onClick={() => loadAcc('loadStatus')} eventKey={step === '4' ? 'openMe' : 'no4'}>
              <Accordion.Header>
                <span>סטטוס הזמנה</span> <small>(#{order.id})</small>
              </Accordion.Header>
              <Accordion.Body>
                {(loadStatus || step === '4') && (
                  <div className='acordCont colorDown noPaddLR' style={{paddingTop: '0'}}>
                    <OrderStatusIndex 
                      orderData={order} 
                      reloadOrders={() => setState(prev => ({ ...prev, reloadOrders: true }))} 
                      reloadOrder={() => getOrder(state.orderId)} 
                    />
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          )}
        </Accordion>
      </div>
    </div>
  );
};

export default SingleOrder;
